<template>
  <div class="notice-container">
    <div class="notice-top flexCenter" style="position: relative">
      <input class="input" placeholder="搜索关键字" v-model="waitSearch" />
      <img
        src="../../assets/images/xiaochazi.png"
        alt=""
        style="width: 18px; left: 300px; position: absolute"
        @click="clearInput"
        v-show="waitSearch !== ''"
      />
      <button class="btn" @click="waitQuery" style="vertical-align: top">
        查询
      </button>
    </div>
    <div class="notice-con">
      <ul v-if="noticeList !== null && noticeList.length > 0">
        <li
          v-for="item in noticeList"
          :key="item.id"
          @click="navBtn(item)"
          class="li-dias"
        >
          <div class="left-text">
            {{ item.title }}
          </div>
          <div>{{ item.updateTime }}</div>
        </li>
      </ul>
      <div v-else class="no-date">暂无相关内容</div>
      <div></div>
      <div class="demo-pagination-block">
        <Pagination :total="total" :page="pageS" key="notice" name="notice" v-show="noticeList !== null && noticeList.length > 0" />
      </div>
    </div>
    <div class="notice-botom"></div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, nextTick } from "vue";
import { fly, queryWebsiteContentAll } from "../../requestUrl";
import setPageText from "./competition/setPageText";
import Pagination from "@/components/Pagination.vue";
import mitt from "@/utils/mitt";
import searchInput from "./competition/searchInputNo";
import { regionCode } from "@/utils/constant";
import { useRouter } from "vue-router";
export default {
  setup() {
    let pageS = ref(1);
    if (sessionStorage.getItem("pageNotice")) {
      pageS.value = +sessionStorage.getItem("pageNotice");
    }
    // if( !pageS.value){
    //     pageS.value = 1
    // }
    const total = ref(10000000000);
    const noticeSearch = ref("");
    const waitSearch = ref("");
    const noticeList = ref([]);
    const router = useRouter();
    const navBtn = function (item) {
      sessionStorage.setItem("pageNotice", pageS.value);
      router.push({
        path: "deailed",
        query: {
          it: JSON.stringify(item),
          name: "公告",
          page: pageS.value,
        },
      });
    };
    const clearInput = function () {
      waitSearch.value = "";
      noticeSearch.value = "";
    };
    const query = function () {
      fly
        .post(queryWebsiteContentAll, {
          regionCode:localStorage.getItem('regionCode'),
          type: 2,
          title: noticeSearch.value,
          pageSize: 15,
          pageNum: pageS.value,
        })
        .then((res) => {
          if (res.data.code != 0) {
            return;
          }
          noticeList.value = res.data.data.datas;

          total.value = res.data.data.total;
        });
    };
    onBeforeUnmount(() => {
      mitt.off("notice", pageQuery);
      mitt.off("", pageQuery);
      const div = document.getElementsByClassName("realimeInfo-bottom")[0];
      const height = div.clientHeight;
      div.style.height = height + "px";
      nextTick(() => {
        div.style.height = "auto";
      });
    });
    //点击查询
    const waitQuery = function () {
      noticeSearch.value = waitSearch.value;
      query();
    };
    function pageQuery(page) {
      pageS.value = page;
      query();
    }
    onMounted(() => {
      query();
      const hash = location.hash.replace(/#\/?/, "");
      console.log(hash);
      mitt.on(hash, pageQuery);
    });
    return {
      ...setPageText(),
      pageS,
      total,
      ...searchInput(),
      noticeSearch,
      navBtn,
      noticeList,
      waitSearch,
      waitQuery,
      clearInput,
    };
  },
  components: {
    Pagination,
  },
};
</script>

<style scoped>
.notice-container {
  width: 100%;
}
.notice-con {
  width: 100%;
}
.notice-con ul {
  min-height: 300px;
}
.notice-con ul li {
  display: flex;
  justify-content: space-between;
  color: #555;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  padding-left: 20px;
  margin-top: 12px;
}
.notice-con ul li div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 40px;
  cursor: pointer;
}
.left-text {
  /* max-width: 995px; */
}
.li-dias {
  width: 98%;
  display: flex;
  justify-content: space-between;
}
li::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: linear-gradient(180deg, #999999 0%, #000000 100%);
  border-radius: 50%;
  position: absolute;
  left: -0px;
  top: 14px;
}
</style>
